<template>
    <div>
        <b-row>
            <b-col md="12" lg="4">
                <b-overlay :show="!dataLoaded">
                    <b-card>
                        <div v-if="!editEnabled">
                            <img v-if="tv_channel.logo && tv_channel.logo.length > 0" :src="'/api/management/v1/image/' + tv_channel.logo" style="max-width: 100%; max-height: 200px" alt="Image" class="mb-3"/>
                            <table>
                                <tr>
                                    <th class="pb-50">
                                        <span class="font-weight-bold mr-2">Enabled</span>
                                    </th>
                                    <td class="pb-50">
                                        <b-badge v-if="tv_channel.enabled" variant="success">
                                            Yes
                                        </b-badge>
                                        <b-badge v-else variant="danger">
                                            No
                                        </b-badge>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="pb-50">
                                        <span class="font-weight-bold mr-2">Name</span>
                                    </th>
                                    <td class="pb-50">
                                        {{ tv_channel.name }}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="pb-50">
                                        <span class="font-weight-bold mr-2">Source</span>
                                    </th>
                                    <td class="pb-50">
                                        {{ tv_channel.source }}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="pb-50">
                                        <span class="font-weight-bold mr-2">Categories</span>
                                    </th>
                                    <td class="pb-50">
                                        {{ channelCategories }}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="pb-50">
                                        <span class="font-weight-bold mr-2">Adult</span>
                                    </th>
                                    <td class="pb-50">
                                        <b-badge v-if="tv_channel.adult" variant="success">
                                            Yes
                                        </b-badge>
                                        <b-badge v-else variant="danger">
                                            No
                                        </b-badge>
                                    </td>
                                </tr>
                            </table>

                        </div>
                        <div v-else>
                            <b-form-group>
                                <b-row>
                                    <b-col>
                                        <b-form-checkbox v-model="editObject.enabled" class="mt-1">Enabled</b-form-checkbox>
                                    </b-col>
                                    <b-col>
                                        <b-form-checkbox v-model="editObject.adult" class="mt-1">Adult</b-form-checkbox>
                                    </b-col>
                                </b-row>

                            </b-form-group>
                            <b-form-group>
                                <label>Name:</label>
                                <b-form-input
                                    type="text"
                                    placeholder="Name"
                                    v-model="editObject.name"
                                />
                            </b-form-group>
                            <b-form-group>
                                <label>Source:</label>
                                <b-form-input
                                    type="text"
                                    placeholder="Source"
                                    v-model="editObject.source"
                                />
                            </b-form-group>

                            <b-form-group>
                                <label>Categories:</label>
                                <v-select
                                    v-model="editObject.selected_categories"
                                    :reduce="category => category.id"
                                    multiple
                                    label="name"
                                    :options="categories"
                                />

                            </b-form-group>

                            <b-form-group>
                                <label>Logo:</label>
                                <ImageSelector v-model="editObject.logo"/>
                            </b-form-group>
                        </div>

                        <hr/>

                        <b-button v-if="!editEnabled" variant="warning" @click="editEnabled = true" class="mr-1">
                            <feather-icon
                                icon="EditIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Edit</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="success" @click="saveChannel" class="mr-1">
                            <feather-icon
                                icon="SaveIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Save</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="danger" @click="editEnabled = false">
                            <feather-icon
                                icon="XIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Cancel</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="danger" @click="removeChannel" class="float-right">
                            <feather-icon
                                icon="Trash2Icon"
                                class="mr-50"
                            />
                            <span class="align-middle">Remove</span>
                        </b-button>

                    </b-card>
                </b-overlay>
            </b-col>
        </b-row>

        <hr/>

        <EPGEntries :channel-id="$route.params.id"/>
    </div>
</template>
<script>

    import { BButton, BCol, BFormCheckbox, BFormGroup, BFormInput, BRow, BCard, BBadge, BOverlay } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import EPGEntries from '@/views/TV/Channels/EPGEntries'
    import ImageSelector from '@/views/components/ImageSelector'

    export default {
        components: {
            ImageSelector,
            EPGEntries,
            BButton,
            BFormInput,
            BFormCheckbox,
            BFormGroup,
            BRow,
            BCol,
            BOverlay,
            BCard,
            BBadge,
            vSelect
        },
        data() {
            return {

                dataLoaded: false,
                tv_channel: {},
                categories: [],

                editEnabled: false,
                editObject: {}

            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false

                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/tv_channel/${  this.$route.params.id}`)
                loadPromise.then(function(response) {
                    thisIns.tv_channel = response.data
                    thisIns.editObject = JSON.parse(JSON.stringify(response.data))
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                const categoryPromise = this.$http.get('/api/management/v1/tv_channel_category')
                categoryPromise.then(function(response) {
                    thisIns.categories = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise, categoryPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            saveChannel() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.put(`/api/management/v1/tv_channel/${  this.$route.params.id}`, this.editObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Item saved')
                    thisIns.editEnabled = false
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            removeChannel() {

                this.dataLoaded = false

                const thisIns = this
                const removePromise = this.$http.delete(`/api/management/v1/tv_channel/${  this.$route.params.id}`)
                removePromise.then(function() {
                    thisIns.$printSuccess('TV Channel removed')
                    thisIns.$nextTick(() => {
                        thisIns.$router.push('/tv_channels')
                    })
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        },
        computed: {
            categoriesMap() {
                if (!this.categories) return {}
                const categories = {}
                this.categories.forEach(function(category) {
                    categories[category.id] = category
                })

                return categories
            },
            channelCategories() {
                if (!this.dataLoaded || !this.tv_channel.selected_categories) return
                const categoriesMap = []
                const thisIns = this
                this.tv_channel.selected_categories.forEach(function(id) {
                    if (thisIns.categoriesMap[id]) {
                        categoriesMap.push(thisIns.categoriesMap[id].name)
                    } else {
                        categoriesMap.push('Unknown')
                    }
                })
                return categoriesMap.join(', ')
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
