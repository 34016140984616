<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <h3 class="mb-3">EPG Entries</h3>
                <div>
                    <b-row>
                        <b-col xl="2" md="4">
                            <label>Start time:</label>
                            <b-form-datepicker v-model="startTime" class="mb-2" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
                        </b-col>
                        <b-col xl="2" md="4">
                            <label>End time:</label>
                            <b-form-datepicker v-model="endTime" class="mb-2" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
                        </b-col>
                        <b-col>
                            <b-button variant="danger" @click="removeEPGEntries" class="mt-2">
                                <feather-icon
                                    icon="Trash2Icon"
                                    class="mr-50"
                                />
                                <span class="align-middle">Remove EPG Data</span>
                            </b-button>
                        </b-col>
                    </b-row>

                </div>
                <hr/>
                <div :key="entry.id" v-for="entry in entries">
                    <div>
                        <b-row>
                            <b-col xl="2" md="3">
                                <img v-if="entry.icon.length > 0" :src="entry.icon" style="max-width: 100%; max-height: 150px"/>
                                <div v-else style="height: 100px" class="d-flex justify-content-center">
                                    <h5 style="text-align: center; line-height: 100px">NO IMAGE</h5>
                                </div>

                            </b-col>
                            <b-col xl="10" md="9">
                                <div class="w-full">
                                    <h5 class="float-left">{{moment(entry.start_time).format('YYYY/MM/DD HH:mm')}} - {{moment(entry.end_time).format('YYYY/MM/DD HH:mm')}}</h5>
                                </div>
                                <div style="clear: both">
                                </div>
                                <h4>{{entry.title}} </h4>
                                <p>{{entry.description}}</p>
                            </b-col>
                        </b-row>
                        <hr/>
                    </div>
                </div>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import { BCard, BOverlay, BRow, BCol, BButton, BFormDatepicker } from 'bootstrap-vue'

    export default {
        components: {
            BOverlay,
            BCard,
            BRow,
            BCol,
            BButton,
            BFormDatepicker
        },
        props: {
            channelId: {
                type: Text,
                required: true
            }
        },
        data() {
            return {

                dataLoaded: false,
                startTime: null,
                endTime: null,
                entries: []

            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false

                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/tv_channel/${  this.channelId  }/epg?start_time=${  this.moment(this.startTime).format('X')  }&end_time=${  this.moment(this.endTime).add(24, 'hours').format('X')}`)
                loadPromise.then(function(response) {
                    thisIns.entries = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            removeEPGEntries() {
                this.dataLoaded = false

                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/tv_channel/${  this.channelId  }/epg`)
                loadPromise.then(function() {
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            }
        },
        watch: {
            startTime() {
                this.loadData()
            },
            endTime() {
                this.loadData()
            }
        },
        created() {
            this.startTime = new Date()
            this.startTime.setHours(0, 0, 0, 0)

            this.endTime = new Date()
            this.endTime.setHours(0, 0, 0, 0)
        },
        mounted() {
            this.loadData()
        }
    }

</script>
